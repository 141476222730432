.kbjacks-modal-2019{
  height:100%;
  width:100%;
  position:fixed;
  top:0;
  left:0;
  background-color: rgba(0,0,0,0.4);
  z-index:9999;
  text-align:center;
  p{
    margin:0;
  }
  h1,h3{
    margin:.5em 0;
  }
  h1{
    font-size:23px;
    color:$navy;
  }
  h3{
    font-size:15px;
    &:nth-child(3){
      font-style:italic;
      color:$red;
    }
  }

  .modal-content{
    padding:1em;
  }
  .modal-content-inner{
    width:100%;
    height:auto;
    max-width:700px;
    background:white;
    margin: 3% auto;
    position:relative;
  }

  .modal-header{
    position:relative;

    img{
      height:auto;
      max-width:19%;
    }
  }

  .close{
    position: absolute;
    right: 0;
    padding: 1em;
    cursor:pointer;
    z-index:1;
    &::after{
      content:"\f00d";
      font-family:FontAwesome;
      font-size:28px;
    }
    &:hover{
      box-shadow: -1px 1px 3px 0px rgba(0,0,0,0.5);
    }
  }

  .modal-body{
    @include flexbox;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    padding:0 .5em;
    h1,h2,h3,h4,p{
      max-width:100%;
      box-sizing:border-box;
    }

    img{
      height:auto;
      max-width:26%;
      padding:1em;
    }
  }

  .modal-footer{
    @include flexbox;
    justify-content: center;
    align-items: center;

    img{
      height:auto;
      max-width:40%;
      margin:1em;
      width:100%;
    }
  }
  .button{
    padding:0 0 1em 0;
    a{
      cursor:pointer;
      background:$blue;
      color:white;
      height:auto;
      display:inline-block;
      padding:.5em;
      &:hover{
        background:$navy;
      }
    }
  }


  &.disabled{
    display:none;
  }


}

@include breakpoint($tab){
  .kbjacks-modal-2019{
    h1{
      font-size:32px;
    }
    h3{
      font-size:18px;
    }
    h1,h3{
      margin:.75em 0;
    }
    .modal-body{
      padding:0 1em;
    }
    .button{
      a{
        padding:1em;
      }
    }
  }
}