.html.front {
  .region-content-top {
    #block-views-home-page-slider-block,
    #block-block-2,
    #block-block-8,
    #block-block-3,
    #block-block-43,
    #block-block-6 {
      //reset styles
      float: none;
      display: block;
      height: auto;
      order: 1;
    }
    //call outs
    #block-block-43,
    #block-block-2,
    #block-block-3,
    #block-block-6 {
      max-width: 255px;
      margin: 0;
      margin-top: 20px;
      margin-right: auto;
      margin-left: auto;
      p {
        margin: 0;
        font-size: 0;
        line-height: 1;
        a {
          display: inline-block;
          image {
            display: inline-block;
          }
        }
      }
    }

    #block-block-43,
    #block-block-3,
    #block-block-6 {
      img {
        max-width: 255px;
        height: auto;
      }
    }

    display: flex;
    flex-direction: column;

    #block-block-8 {
      order: 2;
      width: 100%;
      margin-top: 20px;
    }
  }

  @include breakpoint(720px) {
    .region-content-top {
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      #block-views-home-page-slider-block {
        width: 100%;
      }

      //call outs
      #block-block-43,
      #block-block-2,
      #block-block-3,
      #block-block-6 {
        margin-left: 0;
        margin-right: 0;
      }

      #block-block-3,
      #block-block-43 {
        margin-left: 20px;
      }

      #block-block-8 {
        width: 532px;
        height: 128px;
      }

      #block-block-2 {
        margin: 0;
        margin-top: 20px;
      }

      #block-block-6,
      #block-block-3 {
        height: 200px;
      }
    }
  }

  @include breakpoint(1025px) {
    .region-content-top {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;

      #block-views-home-page-slider-block {
        grid-column-start: 1;
        grid-column-end: span 2;
        grid-row-start: 1;
        grid-row-end: span 2;
        margin: 0;
        box-shadow: none;
        padding-bottom: 20px;
        .view-content {
          box-shadow: 1px 4px 0 0 rgba(0,0,0,.3);
        }
      }

      #block-block-2,
      #block-block-43 {
        grid-column-start: 3;
        grid-column-end: span 1;
        grid-row-start: 1;
        grid-row-end: span 1;
        margin: 0;
        margin-left: 20px;
      }

      #block-block-2 {
        margin-top: 20px;
      }

      #block-block-43 {
        grid-row-start: 2;
      }

      #block-block-8,
      #block-block-6,
      #block-block-3 {
        grid-row-start: 3;
        grid-row-end: span 1;
        grid-column-end: span 1;
        margin: 0;
      }

      #block-block-8 {
        grid-column-start: 1;
        width: 425px;
      }

      #block-block-6 {
        grid-column-start: 3;
        margin-left: 20px;
      }
      #block-block-3 {
        grid-column-start: 2;
        margin-left: 45px;
      }
    }
  }

  @include breakpoint(1100px) {
    .region-content-top {
      #block-block-8 {
        width: 532px;
      }
      #block-block-3 {
        margin-left: 32px;
      }
    }
  }

}
